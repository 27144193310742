import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Sidebar from './Sidebar';


class Navbar extends Component {

  constructor(){
    super()

    this.state={
        menu:'sidebar',
        status:'close'
    }
  }

  toggleSidebar=()=>(event)=>{
    
if(this.state.status==='close'){
  this.setState({menu:'sidebar_responsive'})

  this.setState({status:'open'})
 

}else{
  this.setState({menu:'sidebar'})
  this.setState({status:'close'})
  

}
  }
    render() {
        return (
        
          <>

      <Helmet>
        <link rel="stylesheet" href="navbar.css" />
        
      </Helmet>

        <nav className="navbar">

        <div className="nav_icon" onClick={this.toggleSidebar()}>
          <i className="fa fa-bars" aria-hidden="true"><p>affgdsjfjhfejhej</p></i>
        </div>
     
        <div className="navbar__left">
          <h2>Box Management</h2>
        </div>
        
        <div className="navbar__right">
          <Link to="/login">
            <i className="fa fa-sign-out" aria-hidden="true" style={{color:"red"}}></i>
          </Link>
        </div>
      </nav>
      <Sidebar menuname = {this.state.menu}></Sidebar>
      </>
      
        );
    }
}

export default Navbar;