import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Delivery extends Component {

    constructor(){
        super()

        this.state={
            
           
          loader: false,
           stockdata:[],
           stockdetailsdata:[],
           account: '',  
           accountdata:[],
           delivery:'',
           deliverydata:[],
             


         
          
           
        }
        this.onChange = this.onChange.bind(this);

    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onadd =(id,key)=>(event)=> {

        let deliverydata= [...this.state.deliverydata]
        deliverydata[key]=[id,event.target.value]
        
       // deliverydata.push([id,event.target.value])
        this .setState({deliverydata:deliverydata})
        console.log(deliverydata)

    }
    onupdate=()=>{
        let deliverydata= [...this.state.deliverydata]

        
        
        axios.post('https://box.cmlserver.com/Backend/pages/delivery.php', {deliverydata })
            .then(response=>{
              this.setState({loader:false})
              console.log(response.data)
              
                
                this.setState({deliverydata: [] });
                this.setState({stockdetailsdata: [] });
                this.setState({stockdata: [] });
               
                this.setState({account: '' });
                
                toast.success("Delivery  Success");
       
    
            })
            .catch(error=>{
                toast.error(error)
                this.setState({loader:false})
            })

    }
    onReportshow=()=>{
    
     
      const accname=this.state.account;
     
  
      
     
      if(this.state.account===''){
        toast.error ("Please select Account");
        
      }else{
        this.setState({loader2:true})

      axios.post('https://box.cmlserver.com/Backend/pages/piviewdelivery.php', { accname})
          .then(response=>{
            this.setState({stockdata:response.data})
  
            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })

          axios.post('https://box.cmlserver.com/Backend/pages/piviewdelivery2.php', { accname})
          .then(response=>{
            this.setState({stockdetailsdata:response.data})
  
            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })

    }

        }
  


    componentDidMount(){
      
     

        axios.post('https://box.cmlserver.com/Backend/pages/accountdrop.php',{})
        .then(response=>{

            this.setState({accountdata:response.data})
        
            
        })
        .catch(error=>{
          toast.error(error)
        })


}


    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
  
              </Helmet>
              <ToastContainer />

        <h1 className="printpagehead" style={{color: '#3ea175'}}>Pi Delivery</h1>


        <div style={{marginLeft: '30%', marginRight: '30%'}}>
        <div className="form_item">
                <div style={{width: '30%'}}><label>Account:</label></div>
                <div ><input type="text" list="data1" name="account" onChange={this.onChange} value={this.state.account}/></div>
                  <datalist id="data1">
                    {this.state.accountdata.filter((anyname) => anyname.catagory==='Customer').map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>

      
               

                <div className="form_button">
                <button onClick={this.onReportshow} disabled={this.state.loader2}>
                          {this.state.loader2===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
              </div>
                
              </div>
         
             

              
             
              <table style={{border:'0'}}>
                <thead>


                  
                  <tr style={{border:'1px solid black'}}>
                    <th>SL</th>
                    <th>Date</th>
                    <th>Pi No</th>
                    <th>Name</th>
                    <th>job Work No</th>
                    <th>USD</th>
                    <th>BDT</th>
                    <th>Job Status</th>
                    <th>Pi Status</th>
                    <th>Narration</th>
                  

                   
                  </tr>
                  </thead>

                             {
                                
                             this.state.stockdata.map((item, key) =>
                
                                 
                           
                            
                            


                     
                                                <tr style={{border:'1px solid black'}}>
                                                    <td >{key+1}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.pi_no}</td>
                                                    <td >{item.name}</td>
                                                    <td >Job Work No: {item.job_work_no}

                                                    <tr style={{border:'1px solid green'}}>
                                                                    
                                                                    <td>Item name</td>
                                                                    <td>Qty</td>
                                                                   <td>Delivery</td>
                                                                   <td>Differ</td>
                                                                   <td>New Delivery</td>

                                                                </tr>
                                                    
                                                    {
                                
                                                         this.state.stockdetailsdata.filter((anyname) => anyname.order_id===item.order_id).map((item2, key2) =>  

                                                          
                                                                <tr style={{border:'1px solid green'}}>
                                                                    
                                                                    <td>{item2.name}</td>
                                                                    <td>{Math.trunc(item2.qty)}</td>
                                                                   <td>{Math.trunc(item2.delivery)}</td>


                                                                   <td style={{border:'1px solid green'}}>{item2.qty-item2.delivery}</td>
                                                                   <td>

                                                                   <input type="text" name="delivery" onBlur={this.onadd(item2.id,key2)}  />

                                                                        </td>
                                                                </tr>
                                                            

                                                    )
                                                    }
                                                    
                                                    
                                                    
                                                    
                                                    </td>
                                                    <td >{item.usd}</td>
                                                    <td >{item.amount}</td>
                                                    <td >{item.status}</td>
                                                    <td >{item.pi_no_status}</td>
                                                    <td >{item.narration}</td>
                                                   
                                                  
                                                    
                                                </tr>
                                            )}
                  
                </table>



    
                <div className="form_button">
                <button onClick={this.onupdate} disabled={this.state.loader2}>
                          {this.state.loader2===true?
                             "Loading"
                             :
                             "Update"}
                </button>
              </div>
                
             
            </main>
          
            </>
        );
    }
}

export default Delivery;