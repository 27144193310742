import React, { Component } from 'react';
import { BrowserRouter ,Switch, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from 'axios';

class Login extends Component {

    

    constructor(){

        super()
        this.state={
            username:"",
            password:"",
            loader: false
           
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
 

    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{
        
        const user_name=this.state.username;
        const password=this.state.password;
        this.setState({loader:true})
        axios.post('https://box.cmlserver.com/Backend/pages/login.php', { user_name,password })
            .then(response=>{
                this.setState({loader:false})
                console.log(response)
              if(response.data.status===1){
                sessionStorage.setItem('useri', response.data.user_id);
                sessionStorage.setItem('userkey', response.data.user_key);
                
                
                window.location.replace('/dashboard')
                

              

              }else{
                    alert("Please Type Correct Password");
            }

            })
            .catch(error=>{
                alert(error)
                this.setState({loader:false})
            })
    }

    componentDidMount(){
        // remove all
        sessionStorage.clear();
        
      
        }

    render() {
        return (
            <div>
                         
      <Helmet>
        <link rel="stylesheet" href="login.css" />
      </Helmet>
                <div className="wrapper fadeInDown">
                    <div id="formContent">

                        <h2 className="active"> Sign In </h2>
                       

           
                        <div className="fadeIn first">
                        <img style={{width:"150px",height:"150px"}}src="https://cdn.iconscout.com/icon/free/png-256/user-1648810-1401302.png" id="icon" alt="User Icon" />
                        </div>

                   
                    
                        <input type="text" id="username" className="fadeIn second" value={this.state.username} name="username" onChange={this.onChange} placeholder="login"/>
                        <input type="password" id="password" className="fadeIn third" value={this.state.password} name="password" onChange={this.onChange} placeholder="password"/>
                        <input type="submit" className="fadeIn fourth" onClick={this.onsubmit} disabled={this.state.loader} value={this.state.loader===true?
                             "Loading"
                             :
                             "Submit"} />
                       

                   


                    </div>
                    
                    </div>
                    <div>
                    <a href="https://comillasoft.com">ComillaSoft</a>
                    </div>
                    
            </div>
            
        );
    }
}

export default Login;