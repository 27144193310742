import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Producthistory2 extends Component {

    constructor(){
        super()

        this.state={
            

            loader2: false,
            fromdate: '',
         
            todate: '',
            name: '',
            totalinqty: '',
            totalinqty2: '',
            totaloutqty1: '',
            totaloutqty2: '',
           
            tabledata:[],
      
            totaldata:[],
           statementdata:[],
           stockdatadrop:[],
           companydata:[],
         
          
           
        }
        this.onChange = this.onChange.bind(this);
        this.onReportshow = this.onReportshow.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    componentDidMount(){
      
     

        axios.post('https://box.cmlserver.com/Backend/pages/stockdrop.php',{})
        .then(response=>{

            this.setState({stockdatadrop:response.data})
        
            
        })
        .catch(error=>{
          toast.error(error)
        })

        axios.post('https://box.cmlserver.com/Backend/reports/company.php',{})
        .then(response=>{
  
            this.setState({companydata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })



}

    onReportshow=()=>{
     

      if(this.state.fromdate===''){
      this.setState({fromdatereq:1})

    }if(this.state.todate===''){
        this.setState({todatereq:1})
  
      }else{
        this.setState({fromdatereq:0}) 
        this.setState({todatereq:0}) 

     
      const fromdate=this.state.fromdate;
      const todate=this.state.todate;
      const stockname=this.state.name;
      
   
     
  
      
     
      if(this.state.fromdate===''){
        toast.error ("Please select Date");
        
      }else if(this.state.type===''){
        toast.error ("Please select Type");
        
      }else{
        this.setState({loader2:true})

      axios.post('https://box.cmlserver.com/Backend/reports/producthistory2.php', { fromdate,todate,stockname})
          .then(response=>{
            this.setState({tabledata:response.data})
 
            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })

          axios.post('https://box.cmlserver.com/Backend/reports/total.php', { fromdate,todate,stockname})
          .then(response=>{
            this.setState({totaldata:response.data})
 
            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })

    }

        }
  }

  onPrint=()=>{
window.print();
}



    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
              <ToastContainer />

        <h1 classname="printpagehead" style={{color: '#3ea175'}}>Stock Statement</h1>

        <div style={{marginLeft: '30%', marginRight: '30%'}}>


        
        <div className="form_item">
                <div style={{width: '30%'}}><label>Item Name:</label></div>
                <div  style={{width: '50%'}}><input type="text" list="data1" name="name" onChange={this.onChange} value={this.state.name}/></div>
                  <datalist id="data1">
                    {this.state.stockdatadrop.filter((anyname) => anyname.type==='Raw Material').map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>
      
                <div className="form_item">
                <div style={{width: '30%'}}> <label>From Date :</label></div>
                <div><input type="date" name="fromdate" onChange={this.onChange} value={this.state.fromdate}/></div>
                  
                </div>
                

                <div className="form_item"></div>

                <div className="form_item">
                <div style={{width: '30%'}}><label>To Date :</label></div>
                <div ><input type="date" name="todate" onChange={this.onChange} value={this.state.todate}/></div>
                 
                </div>

                <div className="form_item"></div>


                
                <div className="form_item"></div>
                
                <div className="form_item">
                  
                </div>
                
                <div className="form_item"></div>

                <div className="form_button">
                <button onClick={this.onReportshow} disabled={this.state.loader2}>
                          {this.state.loader2===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
              </div>
                
              </div>

              <hr></hr>

              <div className="print_div" >
                
                <i className="fa fa-print" onClick={this.onPrint} style={{color:'green'}}></i>
                <table width="100%" style={{border:'0'}}>
                  <thead>
                  {this.state.companydata.map((item, key) =>
               <>
                <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><h3>{item.name}</h3></td>
                    
                </tr>
                 
                 <tr>
                 <td colspan="14" style={{textAlign:"center"}} ><h2>{item.address}</h2></td>
                 
                  </tr>
                  <tr>
                               <td colspan="14" style={{textAlign:"center"}} >{item.mobile_no}</td>
                             
                </tr>
                </>
                )}


                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} ><u>Product Statement</u></td>
                  </tr>
                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} >Stock Name:{this.state.name}({this.state.currency}) </td>
                  </tr>
                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} >From {this.state.fromdate} to date {this.state.todate}</td>
                  </tr>
                  </thead>
                  <tr style={{border:'1px solid black'}}>
                
                  <table>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Type 2</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Roll</th>
                    <th>Unit</th>

                    <th>Rate</th>
                    <th>Amount</th>
                  
                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>

                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.type}</td>
                                                    <td >{item.qty_item}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.qty}</td>
                                                    <td >{item.qty2}</td>
                                                    <td >{item.unit}</td>
                                                    <td >{item.rate}</td>
                                                    <td >{item.amount}</td>
                                                   
                                                    
                                                </tr>
                                            )}


                  <tr>
                    
                    <th>Type</th>
                    <th>Qty</th>
                    <th>Roll</th>              
                   
                  </tr>

                  {this.state.totaldata.map((item, key) =>
                  <tr>
                    
                    <th>{item.type}</th>
                    <th>{item.qty}</th>
                    <th>{item.qty2}</th>              
                   
                  </tr>
                  )}

                  
                </table>
                </tr>
                                       
                                     
                </table>

              </div>
    

             
            </main>
          
            </>
        );
    }
}

export default Producthistory2;