
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Deliveryedit extends Component {

    constructor(){
        super()

        this.state={
            accountdata:[],
            tabledata:[],
            deliverydata:[],
            accname:'',
      
            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onadd =(id,key)=>(event)=> {

        let deliverydata= [...this.state.deliverydata]
        deliverydata[key]=[id,event.target.value]
        
       // deliverydata.push([id,event.target.value])
        this .setState({deliverydata:deliverydata})
        

    }

    onsubmit=()=>{

      if(this.state.accname===''){
        toast.error('Please Type Account Name')
        
    }else{
   

      const accname=this.state.accname;
 

      
      axios.post('https://box.cmlserver.com/Backend/pages/job_delivery_view.php', { accname})
          .then(response=>{
            this.setState({loader:false})
            
            console.log(response)
            
            
              
              this.setState({tabledata: response.data });

              
              this.componentDidMount();
              

            

           

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }

 

onUpdate=()=>{
    
    let deliverydata= [...this.state.deliverydata]

        
        
    axios.post('https://box.cmlserver.com/Backend/pages/delivery_edit.php', {deliverydata })
        .then(response=>{
          this.setState({loader:false})
          console.log(response.data)
          
            
            this.setState({deliverydata: [] });
            this.setState({accname:''});
           
            this.setState({tabledata: [] });
            
            toast.success("Delivery Edit Success");
   

        })
        .catch(error=>{
            toast.error(error)
            this.setState({loader:false})
        })
  
 

}

  componentDidMount(){
      
    axios.post('https://box.cmlserver.com/Backend/pages/accountdrop.php',{})
    .then(response=>{

        this.setState({accountdata:response.data})
    
        
    })
    .catch(error=>{
      toast.error(error)
    })


   

  }

    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}>Delivery Edit</h1>

              <div className="form_div">

              <div style={{marginLeft: '30%', marginRight: '30%'}}>
        <div className="form_item">
                <div style={{width: '30%'}}><label>Account:</label></div>
                <div ><input type="text" list="data1" name="accname" onChange={this.onChange} value={this.state.account}/></div>
                  <datalist id="data1">
                    {this.state.accountdata.filter((anyname) => anyname.catagory==='Customer').map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>

      
               

                <div className="form_button">
                <button onClick={this.onsubmit} disabled={this.state.loader2}>
                          {this.state.loader2===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
              </div>
                
              </div>
            
                
              </div>



              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>Sl.</th>
                    <th>Date</th>
                    <th>Job_work_no</th>
                    <th>Po</th>
                    <th>Item Name</th>
                    <th>qty</th>

                    <th>New Qty</th>
                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{key+1}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.job_work_no}</td>
                                                    <td >{item.po}</td>
                                                    <td >{item.name}</td>
                                                    <td >{Math.trunc(item.qty)}</td>
                                                    <td>

                                                    <input type="text" name="newqty" onBlur={this.onadd(item.idjobdelivery,key)}  />

                                                    </td>
                                                   
                                                    <td ></td>
                                                </tr>
                                            )}
                  
                </table>

                <div className="form_button">
                 {this.state.updatebtn===0?
                     <button onClick={this.onUpdate} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                         
                     </button>
                 :
                     
                 
                      <button onClick={this.onUpdate}>
                                                {this.state.loader===true?
                             "Loading"
                             :
                             "Update"}
                      </button>
                  }
               
                
              </div>
              </div>
            </main>
          
            </>
        );
    }
}

export default Deliveryedit;