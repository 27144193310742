import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class User extends Component {

    constructor(){
        super()

        this.state={
            name: '',
            password: '',
            retypepassword: '',
            mobile: '',
            loader: false,
            tabledata:[]
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{

      if(this.state.name===''){
        toast.error('Please Type Name')
        
      }else if(this.state.password===''){
        toast.error('Please Type Password')
      
      }else if(this.state.retypepassword===''){
        toast.error('Please Type Retype Password')
    
      }else if(this.state.mobile===''){
        toast.error('Please Type Mobile')
  
      }else if(this.state.password!==this.state.retypepassword){
        toast.error("Password and retype password not Match")
      }else{

        const username=this.state.name;
        const password=this.state.password;
        const mobileno=this.state.mobile;
        this.setState({loader:true})
        axios.post('https://box.cmlserver.com/Backend/pages/user.php', { username,password,mobileno })
            .then(response=>{
              this.setState({loader:false})
                console.log(response)
              if(response.data===1){
                this.setState({name: '' });
                this.setState({password: '' });
                this.setState({retypepassword: '' });
                this.setState({mobile: '' });
                toast.success("Save Success");
                this.componentDidMount();
                

              

              }else if(response.data===0){
                    toast.error("This user already exit");
            }else{
                toast.warning(response.data);
            }

            })
            .catch(error=>{
                toast.error(error)
                this.setState({loader:false})
            })

          }
    }

    componentDidMount(){
        
        axios.post('https://box.cmlserver.com/Backend/pages/userview.php',{})
            .then(response=>{
    
                this.setState({tabledata:response.data})
            
                
            })
            .catch(error=>{
              toast.error(error)
            })

    }


    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}>User</h1>

             <div className="respo" style={{marginLeft: '30%', marginRight: '30%'}}>
                <div className="form_item">
                  <div style={{width: '50%'}}><label>Name :</label></div>
                  <div style={{width: '50%'}}><input type="text" name="name" onChange={this.onChange} value={this.state.name}/></div>
                </div>

                <div className="form_item">
                  <div style={{width: '50%'}}><label>Password :</label></div>
                  <div style={{width: '50%'}}><input type="password" name="password" onChange={this.onChange} value={this.state.Password}/></div>
                </div>

                <div className="form_item">
                <div style={{width: '50%'}}><label>Retype Password:</label></div>
                <div style={{width: '50%'}}><input type="password" name="retypepassword" onChange={this.onChange} value={this.state.retypepassword}/></div>
                </div>

                <div className="form_item">
                <div style={{width: '50%'}}><label>Mobile:</label></div>
                <div style={{width: '50%'}}><input type="number" name="mobile" onChange={this.onChange} value={this.state.mobile}/></div>
                </div>
                



              

              <div className="form_item">
              <div style={{width: '50%'}}></div>
              <div style={{width: '50%'}}>
                <button onClick={this.onsubmit} disabled={this.state.loader}>
                          {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
                </div>
              </div>
</div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Mobile No</th>
                    <th>Status</th>
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.name}</td>
                                                    <td >X</td>
                                                    <td >{item.status}</td>
                                                </tr>
                                            )}
                  
                </table>
              </div>
            </main>
 
            </>
        );
    }
}

export default User;