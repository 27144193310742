import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Accountbalance extends Component {

    constructor(){
        super()

        this.state={
            
            typereq: 0,
            
            loader: false,
                  
            
            balancedata:[],
            companydata:[],
         
           
        }

    }

  

    componentDidMount(){
      
     

            axios.post('https://box.cmlserver.com/Backend/reports/balance.php',{})
            .then(response=>{
    
                this.setState({balancedata:response.data})
                
            
                
            })
            .catch(error=>{
              toast.error(error)
            })
    
            axios.post('https://box.cmlserver.com/Backend/reports/company.php',{})
            .then(response=>{
      
                this.setState({companydata:response.data})
     
    
                
            })
            .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
            })
    
    }


  
    onPrint=()=>{

     window.print()
  }


  



    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
              <ToastContainer />

                <h1 classname="printpagehead" style={{color: '#3ea175'}}>All Account Balance</h1>
               


              

              <hr></hr>

              <div className="print_div">
               
                <i className="fa fa-print" onClick={this.onPrint} style={{color:'green'}}></i>
                <table width="100%" style={{border:'0'}}>
                  <thead>
                  {this.state.companydata.map((item, key) =>
               <>
                <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><h3>{item.name}</h3></td>
                    
                </tr>
                 
                 <tr>
                 <td colspan="14" style={{textAlign:"center"}} ><h2>{item.address}</h2></td>
                 
                  </tr>
                  <tr>
                               <td colspan="14" style={{textAlign:"center"}} >{item.mobile_no}</td>
                             
                </tr>
                </>
                )}
                  <tr>
                    <td colspan="6" style={{textAlign:"center"}} ><u>Account Balance</u></td>
                  </tr>
  

                  <tr>
                    <th>ID</th>
                    <th>Acc. no</th>
                    <th>Acc. Name</th>
                    <th>Catagory</th>
                    <th>Type</th>
                    <th>Balance</th>
                  </tr>
                  </thead>

                                            {this.state.balancedata.map((item, key) =>
                     
                     
                                                <tr style={{border:'1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.accno}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.catagory}</td>
                                                    <td >{item.type}</td>
                                                    <td >{item.balance}</td>
                                                </tr>
                                            )}
                  
                </table>

              </div>
   

             
            </main>
         
            </>
        );
    }
}

export default Accountbalance;