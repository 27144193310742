import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Stock extends Component {

    constructor(){
        super()

        this.state={
            name: '',
            qty: '0',
            qty_roll: '0',
            unit:'',
            rate:'0',
            type:'Raw Material',
            remark:'',
            
            loader: false,
            tabledata:[],
            
            updatebtn:0,
            editid:'',
            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{

      if(this.state.name===''){
        toast.error('Please Type  Name')
        
    }else if(this.state.qty===''){
      toast.error('Please Select Qty')

    }else if(this.state.qty_roll===''){
      toast.error('Please Select Qty roll')

    }else if(this.state.unit===''){
      toast.error('Please type Unit')

    }else if(this.state.rate===''){
        toast.error('Please type Rate')
  
    }else if(this.state.type===''){
      toast.error('Please type Type')

  }else{
   

      const name=this.state.name;
      const unit=this.state.unit;
      const qty=this.state.qty;
      const qty_roll=this.state.qty_roll;
      const rate=this.state.rate;
      const type=this.state.type;
      const remark=this.state.remark;

      
      axios.post('https://box.cmlserver.com/Backend/pages/stock.php', { name,unit,qty,qty_roll,rate,type,remark })
          .then(response=>{
            this.setState({loader:false})
            console.log(response)
            if(response.data===1){
              
              this.setState({name: '' });
              this.setState({unit: '' });
              this.setState({qty: '0' });
              this.setState({qty_roll: '' });
              this.setState({remark: '' });
              this.setState({rate: '' });
              toast.success("Save Success");
              this.componentDidMount();
              

            

            }
              else{
              toast.warning(response.data);
          }

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }

  onEdit=(name,id,unit,type)=>(event)=>{
    this.setState({name:name})
    this.setState({unit:unit})
    this.setState({type:type})
    this.setState({updatebtn:1})
    this.setState({editid:id})

}

onUpdate=()=>{
    

  if(this.state.name===null){
    toast.error('Please type Name')

  }if(this.state.unit===null){
    toast.error('Please type Unit')

  }else{
      
  }

  const name=this.state.name;
  const remark=this.state.remark;
  const type=this.state.type;
  const unit=this.state.unit;
  const id=this.state.editid;
  this.setState({loader:true})
  axios.post('https://box.cmlserver.com/Backend/pages/stockedit.php',{name,id,unit,remark,type})
  .then(response=>{
      this.setState({loader:false})
      if(response.data===1){
          toast.success("Save Success");
          this.setState({updatebtn:0})
          this.setState({name:''})
          this.setState({unit:''})
          this.setState({remark:''})
       
     
          this.setState({editid:''})
            this.componentDidMount();
        }else{
        toast.warning(response.data);
        
      }
      

      
  })
  .catch(error=>{
      toast.error(error)
      this.setState({loader:false})
  })
 

}

  componentDidMount(){
      
      axios.post('https://box.cmlserver.com/Backend/pages/stockview.php',{})
          .then(response=>{
  
              this.setState({tabledata:response.data})
          
              
          })
          .catch(error=>{
              toast.error(error)
          })


   

  }

    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}><u>New Item</u></h1>
                <br></br>

              <div className="form_div">

              <div className="form_item" >
              <div style={{width: '30%'}}><label>Item Type :</label></div>
              <div style={{width: '50%'}}>
                <select  style={{width: '100%'}} name="type" id="type" onChange={this.onChange} value={this.state.type}>
                  <option value="Raw Material">Raw Material</option>
                  <option value="Ready Good">Ready Good</option>
                
                </select>
                
                </div>
        
              </div>


              <div className="form_item" >
              <div style={{width: '30%'}}><label>Item Name :</label></div>
              <div style={{width: '50%'}}><input type="text" name="name" onChange={this.onChange} value={this.state.name}/></div>
        
              </div>
              

   

 
              <div className="form_item" >
              <div style={{width: '30%'}}><label>Unit :</label></div>
              <div style={{width: '50%'}}><input type="text" name="unit" onChange={this.onChange} value={this.state.unit}/></div>
        
              </div>
              <div className="form_item" >
              <div style={{width: '30%'}}><label>Remark :</label></div>
              <div style={{width: '50%'}}><input type="text" name="remark" onChange={this.onChange} value={this.state.remark}/></div>
        
              </div>
                <div className="form_item">
                  
                </div>
                
              </div>

              <div className="form_button">
                 {this.state.updatebtn===0?
                     <button onClick={this.onsubmit} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                         
                     </button>
                 :
                     
                 
                      <button onClick={this.onUpdate}>
                                                {this.state.loader===true?
                             "Loading"
                             :
                             "Update"}
                      </button>
                  }
               
                
              </div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>SL</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>qty(Weight)</th>
                    <th>qty(Roll)</th>
                    <th>unit</th>

                    <th>Rate</th>
                    <th>Remark</th>
                    <th>Edit</th>
                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.type}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.qty}</td>
                                                    <td >{item.qty2}</td>
                                                    <td >{item.unit}</td>
                                                    <td >{item.avg_rate}</td>
                                                    <td >{item.remark}</td>
                                                   
                                                    <td onClick={this.onEdit(item.name,item.id,item.unit,item.type)} ><i className="fa fa-edit"></i></td>
                                                </tr>
                                            )}
                  
                </table>
              </div>
            </main>
          
            </>
        );
    }
}

export default Stock;