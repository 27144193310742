import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


class Sidebar extends Component {
    constructor(){
        super()

        this.state={
             inv_menu:false,
            stock_menu:false,
            account_menu:false,
            report_menu:false,
            settings_menu:false,
         }
        }
        invsub=()=>{
            if(this.state.inv_menu===true){
               this.setState({inv_menu:false})
            }else{
                this.setState({inv_menu:true})  
                this.setState({stock_menu:false})  
                this.setState({account_menu:false})  
                this.setState({report_menu:false})  
                this.setState({settings_menu:false})  
 
                
            }
        }
        stocksub=()=>{
            if(this.state.stock_menu===true){
                this.setState({stock_menu:false})
            }else{
                this.setState({inv_menu:false})  
                this.setState({stock_menu:true})  
                this.setState({account_menu:false})  
                this.setState({report_menu:false})  
                this.setState({settings_menu:false})  
            }
        }

        accountsub=()=>{
            if(this.state.account_menu===true){
                this.setState({account_menu:false})
            }else{
                this.setState({inv_menu:false})  
                this.setState({stock_menu:false})  
                this.setState({account_menu:true})  
                this.setState({report_menu:false})  
                this.setState({settings_menu:false}) 
            }
        }

        settings=()=>{
            if(this.state.settings_menu===true){
                this.setState({settings_menu:false})
            }else{
                this.setState({inv_menu:false})  
                this.setState({stock_menu:false})  
                this.setState({account_menu:false})  
                this.setState({report_menu:false})  
                this.setState({settings_menu:true})  
            }
        }

        reportsub=()=>{
            if(this.state.report_menu===true){
                this.setState({report_menu:false})
            }else{
                this.setState({inv_menu:false})  
                this.setState({stock_menu:false})  
                this.setState({account_menu:false})  
                this.setState({report_menu:true})  
                this.setState({settings_menu:false})  
            }
        }

    render() {
        return (
            
            <div id={this.props.menuname}>
                <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
 
                <div className="sidebar__menu">
        
                    <h2>Menu</h2>
                    <div className="sidebar__link">
                        <i className="fa fa-home" aria-hidden="true"></i>
                        <Link to="/Dashboard">Dashboard</Link>
                    </div>

  
                    
                    <div className="sidebar__link" onClick={this.invsub}>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        Invoice Menu
                    </div>
                    { this.state.inv_menu===true?
                    <div className="inv_menu" style={{paddingLeft: '15px'}}>

                    <div className="sidebar__link" style={{color: 'yellow'}}>
                        
                        <i className="fa fa-handshake-o"></i>
                        <Link to="/newjobwork">New Job Work</Link>
                        
                    </div>
                    <div className="sidebar__link" style={{color: 'yellow'}}>
                        
                        <i className="fa fa-handshake-o"></i>
                        <Link to="/editjobwork">Edit Job Work</Link>
                        
                    </div>

                    <div className="sidebar__link">
                        <i className="fa fa-handshake-o"></i>
                        <Link to="/picreate">PI Create</Link>
                    </div>

  
                    <div className="sidebar__link">
                        <i className="fa fa-handshake-o"></i>
                        <Link to="/delivery">Delivery Pi</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-handshake-o"></i>
                        <Link to="/deliveryedit">Delivery Edit</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-handshake-o"></i>
                        <Link to="/paymentstatus">Payment Status</Link>
                    </div>



                    
                  
                    </div>
                    :
                    <div></div>
    }

                        <div className="sidebar__link" onClick={this.stocksub}>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        Stock Menu
                    </div>
                    { this.state.stock_menu===true?
                    <div className="inv_menu" style={{paddingLeft: '15px'}}>
                        <div className="sidebar__link">
                            <i className="fa fa-university"></i>
                            <Link to="/stock">New Stock</Link>
                        </div>

                        <div className="sidebar__link">
                            <i className="fa fa-university"></i>
                            <Link to="/stockinout">Stock IN</Link>
                        </div>
                        <div className="sidebar__link">
                            <i className="fa fa-university"></i>
                            <Link to="/stockout">Stock Out</Link>
                        </div>

                        
                    </div>
                    :
                    <div></div>
    }

<div className="sidebar__link" onClick={this.accountsub}>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        Account Menu
                    </div>
                    { this.state.account_menu===true?
                    <div className="inv_menu" style={{paddingLeft: '15px'}}>

                    <div className="sidebar__link">
                        <i className="fa fa-server" aria-hidden="true"></i>
                        <Link to="/account">New Account</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-server" aria-hidden="true"></i>
                        <Link to="/recpay">Rec. pay</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-server" aria-hidden="true"></i>
                        <Link to="/transfer">Transfer</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-server" aria-hidden="true"></i>
                        <Link to="/bulktransfer">Bulk Transfer</Link>
                    </div>

                   

                   
                    </div>
                    :
                    <div></div>
    }

<div className="sidebar__link" onClick={this.settings}>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        Settings Menu
                    </div>
                    { this.state.settings_menu===true?
                    <div className="inv_menu" style={{paddingLeft: '15px'}}>

                    <div className="sidebar__link">
                        <i className="fa fa-user-o"></i>
                        <Link to="/user">User</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-user-o"></i>
                        <Link to="/passwordchange">Password Change</Link>
                    </div>

                   
                    </div>
                    :
                    <div></div>
    }



    <div className="sidebar__link" onClick={this.reportsub}>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        Report Menu
                    </div>
                    { this.state.report_menu===true?
                    <div className="inv_menu" style={{paddingLeft: '15px'}}>

                    
<div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/stockbalance">Stock Balance</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/stockdetails">Stock Details</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/stockbal2"> Stock Report </Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/producthistory2"> Product History</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/producthistory">Job Delivery His.</Link>
                    </div>

                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/accountbalance">Acc Balance</Link>
                    </div>

                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/accountstatement">Acc Statement</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/accountstatement2">Cash Statement</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/dailyreport">Daily Report</Link>
                    </div>
                    


                    
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/piview"> Piview</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/piviewcus"> Pi view customer</Link>
                    </div>
                    <div className="sidebar__link">
                        <i className="fa fa-sticky-note-o"></i>
                        <Link to="/piviewdate"> Pi view Date</Link>
                    </div>

                   
                    

                    
                    
                    </div>
                    :
                    <div></div>
    }
                    


                    </div>
                    </div>
                    
              
        );
    }
}

export default Sidebar;