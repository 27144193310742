import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Stockbal2 extends Component {

    constructor(){
        super()

        this.state={
            

            loader2: false,
            fromdate: '',
            
            todate: '',
      
        
           statementdata:[],
           companydata:[],
         
          
           
        }
        this.onChange = this.onChange.bind(this);
        this.onReportshow = this.onReportshow.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    componentDidMount(){
      
     

        axios.post('https://box.cmlserver.com/Backend/pages/stockdrop.php',{})
        .then(response=>{

            this.setState({stockdata:response.data})
        
            
        })
        .catch(error=>{
          toast.error(error)
        })

        axios.post('https://box.cmlserver.com/Backend/reports/company.php',{})
        .then(response=>{
  
            this.setState({companydata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })



}

    onReportshow=()=>{
     

      if(this.state.fromdate===''){
      this.setState({fromdatereq:1})

    }if(this.state.todate===''){
        this.setState({todatereq:1})
  
      }else{
        this.setState({fromdatereq:0}) 
        this.setState({todatereq:0}) 

     
      const fromdate=this.state.fromdate;
      const todate=this.state.todate;
     
   
     
  
      
     
      if(this.state.fromdate===''){
        toast.error ("Please select Date");
        
      }else{
        this.setState({loader2:true})

      axios.post('https://box.cmlserver.com/Backend/reports/stockbal2.php', { fromdate,todate})
          .then(response=>{
            this.setState({statementdata:response.data})
 
            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })

    }

        }
  }

  onPrint=()=>{
window.print();
}



    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
              <ToastContainer />

        <h1 classname="printpagehead" style={{color: '#3ea175'}}>Item Statement</h1>

        <div style={{marginLeft: '30%', marginRight: '30%'}}>


                <div className="form_item">
                <div style={{width: '30%'}}> <label>From Date :</label></div>
                <div><input type="date" name="fromdate" onChange={this.onChange} value={this.state.fromdate}/></div>
                  
                </div>
                

                <div className="form_item"></div>

                <div className="form_item">
                <div style={{width: '30%'}}><label>To Date :</label></div>
                <div ><input type="date" name="todate" onChange={this.onChange} value={this.state.todate}/></div>
                 
                </div>

                <div className="form_item"></div>


                
                <div className="form_item"></div>
                
                <div className="form_item">
                  
                </div>
                
                <div className="form_item"></div>

                <div className="form_button">
                <button onClick={this.onReportshow} disabled={this.state.loader2}>
                          {this.state.loader2===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
              </div>
                
              </div>

              <hr></hr>

              <div className="print_div" >
                
                <i className="fa fa-print" onClick={this.onPrint} style={{color:'green'}}></i>
                <table width="100%" style={{border:'0'}}>
                  <thead>
                  {this.state.companydata.map((item, key) =>
               <>
                <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><h3>{item.name}</h3></td>
                    
                </tr>
                 
                 <tr>
                 <td colspan="14" style={{textAlign:"center"}} ><h2>{item.address}</h2></td>
                 
                  </tr>
                  <tr>
                               <td colspan="14" style={{textAlign:"center"}} >{item.mobile_no}</td>
                             
                </tr>
                </>
                )}


                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} ><u>Statement</u></td>
                  </tr>

                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} >From {this.state.fromdate} to date {this.state.todate}</td>
                  </tr>
                  <tr style={{border:'1px solid black'}}>
                    <th>Sl</th>
                    <th>Item Name</th>
                    <th>Pre. Balance</th>
                    <th>IN</th>
                     
                    <th>Out</th>
                    <th>Balance</th>


                  </tr>
                  </thead>

                                            {
                                             

                                            this.state.statementdata.map((item, key) =>
                                            
                                                <tr style={{border:'1px solid black'}}>
                                                    <td >{key+1}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.ob}</td>
                                                    <td >{item.in}</td>
                                                    <td >{item.out}</td>
                                                    <td >{item.balance}</td>

                                                    
                                                </tr>
                                               
                                            )}
                                       
                                       <tr style={{border:'1px solid black'}}>
                                                    <td colspan="3">Total</td>
                                                     <td >{this.state.sumdr}</td>
                                                    <td >{this.state.sumcr}</td>
                                                    <td colspan="2"></td>
                                                    
                                                </tr>
                </table>

              </div>
    

             
            </main>
          
            </>
        );
    }
}

export default Stockbal2;