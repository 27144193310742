import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Bulktransfer extends Component {

    constructor(){
        super()

        this.state={
            typerec:'',
            typepay:'',
            accnamerec: '',
            accnamepay: '',
            balancerec:'',
            balancepay:'',
            narration:'',
            
            loader: false,
            tabledata:[],
            accountdata:[],
            catagorydata:[],
            typerecdata:[],
            typepaydata:[],
 
            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }


    onrec=(event)=>{
        this.setState({ [event.target.name]: event.target.value })
       var type=event.target.value;
      
      
       axios.post('https://box.cmlserver.com/Backend/pages/accdropcata.php',{type})
       .then(response=>{
        console.log(response.data)

           this.setState({typerecdata:response.data})
       
           
       })
       .catch(error=>{
         toast.error(error)
       })


    }

    onpay=(event)=>{
        this.setState({ [event.target.name]: event.target.value })
       var type=event.target.value;
      
      
       axios.post('https://box.cmlserver.com/Backend/pages/accdropcata.php',{type})
       .then(response=>{
        console.log(response.data)

           this.setState({typepaydata:response.data})
       
           
       })
       .catch(error=>{
         toast.error(error)
       })


    }

    recupdate=(accname,key)=>event=>{
        

      let data= [...this.state.typerecdata];

    data[key]['amount']=event.target.value;
    this.setState({typerecdata:data })
    
        
    


    }

    payupdate=(accname,key)=>event=>{
        

      let data= [...this.state.typepaydata];

    data[key]['amount']=event.target.value;
    this.setState({typepaydata:data })
    
        
    


    }


    onsubmit=()=>{

    if(this.state.narration===''){
        toast.error('Please type Narration')
  
      
    }else{
   

      const typerecdata=this.state.typerecdata;
      const typepaydata=this.state.typepaydata;
      const narration=this.state.narration;     
     

      
      axios.post('https://box.cmlserver.com/Backend/pages/bulktransfer.php', { typerecdata,typepaydata,narration })
          .then(response=>{
            this.setState({loader:false})
            console.log(response.data)
            
            if(response.data===1){
              
              this.setState({typerec: '' });
              this.setState({typerecdata: [] });
              
              this.setState({typepay: '' });
              this.setState({typepaydata: [] });
              
              this.setState({narration: '' });
              toast.success("Save Success");
              this.componentDidMount();
              

            

            }
              else{
              toast.warning(response.data);
          }

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }





  componentDidMount(){
      
      axios.post('https://box.cmlserver.com/Backend/pages/trview.php',{})
          .then(response=>{
  
              this.setState({tabledata:response.data})
          
              
          })
          .catch(error=>{
              toast.error(error)
          })


          axios.post('https://box.cmlserver.com/Backend/pages/accountdrop.php',{})
          .then(response=>{
  
              this.setState({accountdata:response.data})
          
              
          })
          .catch(error=>{
            toast.error(error)
          })

          axios.post('https://box.cmlserver.com/Backend/pages/catagoryaccdrop.php',{})
          .then(response=>{
  
              this.setState({catagorydata:response.data})
          
              
          })
          .catch(error=>{
            toast.error(error)
          })

  }

    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}>Bulk Transfer</h1>

              <div className="form_divx">


              <div className="form_item">
                <div style={{width: '30%'}}><label>Receive Bulk :</label></div>
                <div style={{width: '50%'}}> <input type="text" list="data5" name="typerec" onChange={this.onrec} value={this.state.typerec}/></div>
                    <datalist id="data5">
                    {this.state.catagorydata.map((item, key) =>
                    <option>{item.catagory}</option>
                    )}
                            
                      </datalist>
                </div>

                <div className="form_item">
                <div style={{width: '30%'}}><label>Payment Bulk:</label></div>
                <div style={{width: '50%'}}> <input type="text" list="data6" name="typepay" onChange={this.onpay} value={this.state.typepay}/></div>
                    <datalist id="data6">
                    {this.state.catagorydata.map((item, key) =>
                    <option>{item.catagory}</option>
                    )}
                      </datalist>
                </div>

<table>
    <tr>
        <td style={{verticalAlign:'top'}}>
              
              

              {this.state.typerecdata.map((itemx, keyx) =>
            
            <tr>
                <td>
                
                    <input style={{height: '30px'}}  type="text" list="data2" name="accnamerec" value={itemx.name} readOnly />
                  <datalist id="data2">
                    {this.state.accountdata.map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </td>
                <td><input style={{height: '30px'}} type="text" name="balancerec" onChange={this.recupdate(itemx.name,keyx)} /></td>
                </tr>   
              )}
             



        </td>

        <td style={{verticalAlign:'top'}}>
              
              

              {this.state.typepaydata.map((itemx, keyx) =>
            
            <tr>
                <td>
                
                    <input style={{height: '30px'}}  type="text" list="data2" name="accnamepay" value={itemx.name} readOnly />
                  <datalist id="data2">
                    {this.state.accountdata.map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </td>
                <td><input style={{height: '30px'}} type="text" name="balancerec" onChange={this.payupdate(itemx.name,keyx)} /></td>
                </tr>   
              )}
             



        </td>
    </tr>
</table>



              <div className="form_item" >
              <div style={{width: '30%'}}><label>Narration :</label></div>
              <div style={{width: '50%'}}><input type="text" name="narration" onChange={this.onChange} value={this.state.narration}/></div>
        
              </div>




                
              </div>

              <div className="form_button">
                
                     <button onClick={this.onsubmit} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                         
                     </button>
               
               
                
              </div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Tr. No</th>
                    <th>Account No</th>
                    <th>Name</th>
                    <th>Rec</th>
                    <th>Pay</th>

                    <th>Narration</th>
                    
                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border:'1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.tr_no}</td>
                                                    <td >{item.acc_no}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.rec}</td>
                                                    <td >{item.pay}</td>
                                                   <td >{item.narration}</td>
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>
              </div>
            </main>
          
            </>
        );
    }
}

export default Bulktransfer;