import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Passwordchange extends Component {

    constructor(){
        super()

        this.state={
            oldpassword: '',
            newpassword: '',
            retypepassword: '',
            loader: false,
            tabledata:[]
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{

      if(this.state.oldpassword===''){
        toast.error('Please Type Old Password')
        
      }else if(this.state.newpassword===''){
        toast.error('Please Type New Password')
    
      }else if(this.state.retypepassword===''){
        toast.error('Please Type Retype Password')
    
      }else if(this.state.newpassword!==this.state.retypepassword){
        toast.error("Password and retype password not Match")
      }else{

        const oldpassword=this.state.oldpassword;
        const retypepassword=this.state.retypepassword;
        const userid=sessionStorage.getItem('useri');
      const useracc=sessionStorage.getItem('usera');
   
        this.setState({loader:true})
        axios.post('https://box.cmlserver.com/Backend/pages/password.php', { retypepassword,oldpassword,userid})
            .then(response=>{
              this.setState({loader:false})
                console.log(response)
              if(response.data===1){
                toast.error("User Not Found!");
              }else if(response.data===2){
                toast.error("Old Password Not Match!");
              }else{
                this.setState({password: '' });
                this.setState({retypepassword: '' });
                this.setState({oldpassword: '' });
                toast.success("Save Success");
                this.componentDidMount();
              }

 

            })
            .catch(error=>{
                toast.error(error)
                this.setState({loader:false})
            })

          }
    }

    componentDidMount(){
        
        axios.post('https://box.cmlserver.com/Backend/pages/userview.php',{})
            .then(response=>{
    
                this.setState({tabledata:response.data})
            
                
            })
            .catch(error=>{
              toast.error(error)
            })

    }


    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}>Password Change</h1>

              <div style={{marginLeft: '30%', marginRight: '30%'}}>

              <div className="form_item">
              <div style={{width: '50%'}}><label>Old Password :</label></div>
              <div style={{width: '50%'}}><input type="password" name="oldpassword" onChange={this.onChange} value={this.state.oldpassword}/></div>
                </div>

                <div className="form_item">
                  
                </div>

                <div className="form_item">
                <div style={{width: '50%'}}><label>New Password :</label></div>
                <div style={{width: '50%'}}><input type="password" name="newpassword" onChange={this.onChange} value={this.state.newpassword}/></div>
                </div>

                <div className="form_item">
                  
                  </div>

                <div className="form_item">
                <div style={{width: '50%'}}><label>Retype Password:</label></div>
                <div style={{width: '50%'}}><input type="password" name="retypepassword" onChange={this.onChange} value={this.state.retypepassword}/></div>
                </div>

                <div className="form_item">
                  
                  </div>

              </div>

              <div className="form_item">
              <div style={{width: '50%'}}></div>
              <div style={{width: '50%'}}>
                <button onClick={this.onsubmit} disabled={this.state.loader}>
                          {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
                </div>
              </div>


              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Mobile No</th>
                    <th>Status</th>
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.name}</td>
                                                    <td >X</td>
                                                    <td >{item.status}</td>
                                                </tr>
                                            )}
                  
                </table>
              </div>
            </main>
 
            </>
        );
    }
}

export default Passwordchange;