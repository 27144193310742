import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Account extends Component {

    constructor(){
        super()

        this.state={
            accname: '',
            type: '',
            balance:'',
            catagory:'',
            Status:'',
            
            loader: false,
            tabledata:[],
            ledgerdata:[],
            updatebtn:0,
            editaccno:'',
            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{

      if(this.state.accname===''){
        toast.error('Please Type Account Name')
        
    }else if(this.state.type===''){
      toast.error('Please Select Type')

    }else if(this.state.balance===''){
      toast.error('Please type Balance')

    }else if(this.state.catagory===''){
      toast.error('Please type catagory')

    }else{
   

      const accname=this.state.accname;
      const type=this.state.type;
      const status=this.state.status;
      const catagory=this.state.catagory;
     
      const balance=this.state.balance;

      
      axios.post('https://box.cmlserver.com/Backend/pages/account.php', { accname,type,balance ,catagory,status})
          .then(response=>{
            this.setState({loader:false})
            console.log(response)
            if(response.data===1){
              
              this.setState({accname: '' });
              this.setState({type: '' });
              this.setState({status: '' });
              this.setState({balance: '' });
              this.setState({catagory: '' });
              toast.success("Save Success");
              this.componentDidMount();
              

            

            }
              else{
              toast.warning(response.data);
          }

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }

  onEdit=(name,accno,status)=>(event)=>{
    this.setState({accname:name})
    this.setState({status:status})
    this.setState({updatebtn:1})
    this.setState({editaccno:accno})

}

onUpdate=()=>{
    

  if(this.state.accname===null){
    toast.error('Please full all required filed')

  }else{
      
  }

  const name=this.state.accname;
  const status=this.state.status;
  const accno=this.state.editaccno;
  this.setState({loader:true})
  axios.post('https://box.cmlserver.com/Backend/pages/accountedit.php',{name,accno,status})
  .then(response=>{
      this.setState({loader:false})
      if(response.data===1){
          toast.success("Save Success");
          this.setState({updatebtn:0})
          this.setState({name:''})
          this.setState({status:''})
          this.setState({balance:''})
          this.setState({type:''})
     
          this.setState({editaccno:''})
            this.componentDidMount();
        }else{
        toast.warning(response.data);
        
      }
      

      
  })
  .catch(error=>{
      toast.error(error)
      this.setState({loader:false})
  })
 

}

  componentDidMount(){
      
      axios.post('https://box.cmlserver.com/Backend/pages/accountview.php',{})
          .then(response=>{
  
              this.setState({tabledata:response.data})
          
              
          })
          .catch(error=>{
              toast.error(error)
          })


   

  }

    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}>Account</h1>

              <div className="form_div">



              <div className="form_item" >
              <div style={{width: '30%'}}><label>Account Name :</label></div>
              <div style={{width: '50%'}}><input type="text" name="accname" onChange={this.onChange} value={this.state.accname}/></div>
        
              </div>
              

              <div className="form_item" >
              <div style={{width: '30%'}}><label>Opening Balance :</label></div>
              <div style={{width: '50%'}}><input type="text" name="balance" onChange={this.onChange} value={this.state.balance}/></div>
        
              </div>


      

                <div className="form_item">
                <div style={{width: '30%'}}><label>Type:</label></div>
                <div style={{width: '50%'}}> <input type="text" list="data5" name="type" onChange={this.onChange} value={this.state.type}/></div>
                    <datalist id="data5">
                            <option >Receive</option>
                            <option >Payment</option>
                            
                      </datalist>
                </div>
            

                <div className="form_item">
                <div style={{width: '30%'}}><label>Catagory:</label></div>
                <div style={{width: '50%'}}> <input type="text" list="data6" name="catagory" onChange={this.onChange} value={this.state.catagory} autoComplete='off'/></div>
                    <datalist id="data6">
                            <option value="Assets">Assets--REC</option>
                            <option value="Expense">Expense--REC</option>
                            <option value="Customer">Customer--REC</option>
                            <option value="Supplier">Supplier--PAY</option>
                            <option value="Income">Income--PAY</option>
                            <option value="Staff">Staff--PAY</option>
                            <option value="Income">Liabilities--PAY</option>
                            
                      </datalist>
                </div>

                <div className="form_item">
                <div style={{width: '30%'}}><label>Status:</label></div>
                <div style={{width: '50%'}}> <input type="text" list="data7" name="status" onChange={this.onChange} value={this.state.status}/></div>
                    <datalist id="data7">
                            <option >Active</option>
                            <option >Inactive</option>
                            
                      </datalist>
                </div>
                <div className="form_item"></div>
                
              </div>

              <div className="form_button">
                 {this.state.updatebtn===0?
                     <button onClick={this.onsubmit} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                         
                     </button>
                 :
                     
                 
                      <button onClick={this.onUpdate}>
                                                {this.state.loader===true?
                             "Loading"
                             :
                             "Update"}
                      </button>
                  }
               
                
              </div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Account No</th>
                    <th>Name</th>
                    <th>Balance</th>

                    <th>Rec pay acc</th>
                    <th>Catagory</th>
                    <th>Status</th>
                    <th>Edit</th>
                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.accno}</td>
                                                    <td >{item.name}</td>
                                                   
                                                    <td >{item.balance}</td>
                                                    <td >{item.type}</td>
                                                    <td >{item.catagory}</td>
                                                    <td >{item.status}</td>
                                                   
                                                    <td onClick={this.onEdit(item.name,item.accno,item.status)} ><i className="fa fa-edit"></i></td>
                                                </tr>
                                            )}
                  
                </table>
              </div>
            </main>
          
            </>
        );
    }
}

export default Account;