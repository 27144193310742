import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Transfer extends Component {

    constructor(){
        super()

        this.state={
            accnamerec: '',
            accnamepay: '',
            balance:'',
            narration:'',
            
            loader: false,
            tabledata:[],
            accountdata:[]
 
            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{

      if(this.state.accnamerec===''){
        toast.error('Please Type Account Rec')
        
    }else if(this.state.accnamepay===''){
      toast.error('Please Select Account Pay')

    }else if(this.state.balance===''){
      toast.error('Please type Balance')

    }else if(this.state.narration===''){
        toast.error('Please type Narration')
  
      
    }else{
   

      const accnamerec=this.state.accnamerec;
      const accnamepay=this.state.accnamepay;
      const narration=this.state.narration;     
      const balance=this.state.balance;

      
      axios.post('https://box.cmlserver.com/Backend/pages/transfer.php', { accnamerec,accnamepay,balance,narration })
          .then(response=>{
            this.setState({loader:false})
            console.log(response.data)
            
            if(response.data===1){
              
              this.setState({accnamerec: '' });
              this.setState({accnamepay: '' });
              this.setState({balance: '' });
              this.setState({narration: '' });
              toast.success("Save Success");
              this.componentDidMount();
              

            

            }
              else{
              toast.warning(response.data);
          }

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }





  componentDidMount(){
      
      axios.post('https://box.cmlserver.com/Backend/pages/trview.php',{})
          .then(response=>{
  
              this.setState({tabledata:response.data})
          
              
          })
          .catch(error=>{
              toast.error(error)
          })


          axios.post('https://box.cmlserver.com/Backend/pages/accountdrop.php',{})
          .then(response=>{
  
              this.setState({accountdata:response.data})
          
              
          })
          .catch(error=>{
            toast.error(error)
          })

  }

    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}>Transfer</h1>

              <div className="form_divx">





                <div className="form_item">
                <div style={{width: '30%'}}><label>Account Name (Rec):</label></div>
                <div style={{width: '50%'}}><input type="text" list="data1" name="accnamerec" onChange={this.onChange} value={this.state.accnamerec}/></div>
                  <datalist id="data1">
                    {this.state.accountdata.map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>


                <div className="form_item">
                <div style={{width: '30%'}}><label>Account Name (Pay):</label></div>
                <div style={{width: '50%'}}><input type="text" list="data2" name="accnamepay" onChange={this.onChange} value={this.state.accnamepay}/></div>
                  <datalist id="data2">
                    {this.state.accountdata.map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>

              

              <div className="form_item" >
              <div style={{width: '30%'}}><label>Amount :</label></div>
              <div style={{width: '50%'}}><input type="text" name="balance" onChange={this.onChange} value={this.state.balance}/></div>
        
              </div>
              <div className="form_item" >
              <div style={{width: '30%'}}><label>Narration :</label></div>
              <div style={{width: '50%'}}><input type="text" name="narration" onChange={this.onChange} value={this.state.narration}/></div>
        
              </div>




                
              </div>

              <div className="form_button">
                
                     <button onClick={this.onsubmit} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                         
                     </button>
               
               
                
              </div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Tr. No</th>
                    <th>Account No</th>
                    <th>Name</th>
                    <th>Rec</th>
                    <th>Pay</th>

                    <th>Narration</th>
                    
                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border:'1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.tr_no}</td>
                                                    <td >{item.acc_no}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.rec}</td>
                                                    <td >{item.pay}</td>
                                                   <td >{item.narration}</td>
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>
              </div>
            </main>
          
            </>
        );
    }
}

export default Transfer;