import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate  } from 'react-router-dom';


import Login from './myComponets/Login'; 
import Dashboard from './myComponets/Dashboard';
import Account from './myComponets/Account';
import Recpay from './myComponets/Recpay';
import Transfer from './myComponets/Transfer';
import Bulktransfer from './myComponets/Bulktransfer';
import Stock from './myComponets/Stock';
import StockInOut from './myComponets/StockInOut';
import Stockout from './myComponets/Stockout';
import JobWork from './myComponets/JobWork';
import Editjob from './myComponets/Editjob';
import Pi from './myComponets/Pi';
import Jobdelivery from './myComponets/Jobdelivery';
import User from './myComponets/User';
import Passwordchange from './myComponets/Passwordchange';
import Stockbalance from './myComponets/Stockbalance';
import Stockdetails from './myComponets/Stockdetails';
import Accountbalance from './myComponets/Accountbalance';
import Accountstatement from './myComponets/Accountstatement';
import Stockbal2 from './myComponets/Stockbal2';
import Piview from './myComponets/Piview';
import Piviewcus from './myComponets/Piviewcus';
import Delivery from './myComponets/Delivery';
import Lastjob from './myComponets/Lastjob';
import Deliveryedit from './myComponets/Deliveryedit';
import Paymentstatus from './myComponets/Paymentstatus';
import Piviewdate from './myComponets/Piviewdate';
import Producthistory from './myComponets/Producthistory';
import Producthistory2 from './myComponets/Producthistory2';
import Accountstatement2 from './myComponets/Accountstatement2';
import Dailyreport from './myComponets/Dailyreport';




function App() {
  const currentURL = window.location.pathname
  

  return (
    
    <div className="App">
      <div className="container">

      <Router>


      { 
     
     
     sessionStorage.getItem('useri')===null? <Navigate to="/login" /> :''
       }
        <Routes>  
        <Route path="/" element={<Dashboard />} />
           <Route path="/dashboard" element={<Dashboard />} />
           <Route path="/login" element={<Login />} />
           <Route path="/account" element={<Account />} />
           <Route path="/recpay" element={<Recpay />} />
           <Route path="/transfer" element={<Transfer />} />
           <Route path="/bulktransfer" element={<Bulktransfer />} />
           <Route path="/stock" element={<Stock />} />
           <Route path="/stockinout" element={<StockInOut />} />
           <Route path="/stockout" element={<Stockout />} />
           <Route path="/newjobwork" element={<JobWork />} />
           <Route path="/editjobwork" element={<Editjob />} />
           <Route path="/picreate" element={<Pi />} />
           <Route path="/Jobdelivery" element={<Jobdelivery />} />
           <Route path="/user" element={<User />} />
           <Route path="/passwordchange" element={<Passwordchange />} />
           <Route path="/stockbalance" element={<Stockbalance />} />
           <Route path="/stockdetails" element={<Stockdetails />} />
           <Route path="/accountbalance" element={<Accountbalance />} />
           <Route path="/accountstatement" element={<Accountstatement />} />
           <Route path="/stockbal2" element={<Stockbal2 />} />
           <Route path="/piview" element={<Piview />} />
           <Route path="/piviewcus" element={<Piviewcus />} />
           <Route path="/delivery" element={<Delivery />} />
           <Route path="/lastjob" element={<Lastjob />} />
           <Route path="/deliveryedit" element={<Deliveryedit />} />
           <Route path="/paymentstatus" element={<Paymentstatus />} />
           <Route path="/piviewdate" element={<Piviewdate />} />
           <Route path="/producthistory" element={<Producthistory />} />
           <Route path="/producthistory2" element={<Producthistory2 />} />
           <Route path="/accountstatement2" element={<Accountstatement2 />} />
           <Route path="/dailyreport" element={<Dailyreport />} />
           
        </Routes>
      
      </Router>

      </div>
      
    </div>
  );
}

export default App;
