import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Piviewdate extends Component {

    constructor(){
        super()

        this.state={
            
           
            loader: false,
           stockdata:[],
           companydata:[],
           fromdate:'',
           todate:'',
           totalusd:'',
           totalamount:'',

        }
        this.onChange = this.onChange.bind(this);
        this.onReportshow = this.onReportshow.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }





    onReportshow=()=>{
        const fromdate=this.state.fromdate;
        const todate=this.state.todate;
      
        axios.post('https://box.cmlserver.com/Backend/reports/piviewdate.php',{fromdate,todate})
        .then(response=>{
  
            this.setState({stockdata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })

        axios.post('https://box.cmlserver.com/Backend/reports/company.php',{})
        .then(response=>{
  
            this.setState({companydata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })
    
    }

    onPrint=()=>{
      window.print();
    }
    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
              <ToastContainer />

              <div style={{marginLeft: '30%', marginRight: '30%'}}>


      
                <div className="form_item">
                <div style={{width: '30%'}}> <label>From Date :</label></div>
                <div><input type="date" name="fromdate" onChange={this.onChange} value={this.state.fromdate}/></div>
                  
                </div>
                

                <div className="form_item"></div>

                <div className="form_item">
                <div style={{width: '30%'}}><label>To Date :</label></div>
                <div ><input type="date" name="todate" onChange={this.onChange} value={this.state.todate}/></div>
                 
                </div>

                <div className="form_item"></div>


                
                <div className="form_item"></div>
                
                <div className="form_item">
                  
                </div>
                
                <div className="form_item"></div>

                <div className="form_button">
                <button onClick={this.onReportshow} disabled={this.state.loader2}>
                          {this.state.loader2===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
              </div>
                
              </div>



         
             

              <div className="print_div">
              <i className="fa fa-print" onClick={this.onPrint} style={{color:'green'}}></i>
              <table style={{border:'0'}}>
                <thead>
                {this.state.companydata.map((item, key) =>
               <>
                <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><h3>{item.name}</h3></td>
                    
                </tr>
                 
                 <tr>
                 <td colspan="14" style={{textAlign:"center"}} ><h2>{item.address}</h2></td>
                 
                  </tr>
                  <tr>
                               <td colspan="14" style={{textAlign:"center"}} >{item.mobile_no}</td>
                             
                </tr>
                <tr>
                               <td colspan="14" style={{textAlign:"center"}} >From Date: {this.state.fromdate} to Date {this.state.todate}</td>
                             
                </tr>
                </>
                )}
                  <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><u>Pi view</u></td>
                  </tr>

                  
                  <tr style={{border:'1px solid black'}}>
                    <th>SL</th>
                    <th>Date</th>
                    <th>Pi No</th>
                    <th>Name</th>
                    <th>job Work No</th>
                    <th>Po No</th>
                    <th>USD</th>
                    <th>Amount</th>
                    <th>Job Status</th>
                    <th>PI Status</th>
                    <th>Payment Status</th>
                    <th>Narration</th>
                  

                   
                  </tr>
                  </thead>

                             {
                                
                             this.state.stockdata.map((item, key) =>
                             

                            
                                

                           
                            
                            


                     
                                                <tr style={{border:'1px solid black', width:'100%'}}>
                                                    <td >{key+1}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.pi_no}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.job_work_no}</td>
                                                    <td >{item.po}</td>
                                                    <td >{Math.trunc(item.usd)}</td>
                                                    <td >{Math.trunc(item.amount)}</td>
                                                    <td >{item.status}</td>
                                                    <td >{item.pi_no_status}</td>
                                                    <td >{item.payment_status}</td>
                                                    <td >{item.narration}</td>
                                                   
                                                  

                                                    
                                                </tr>


                                            )}


                  
                </table>

              </div>


    

             
            </main>
          
            </>
        );
    }
}

export default Piviewdate;