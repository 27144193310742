import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Pi extends Component {

    constructor(){
        super()

        this.state={
            name: '',

            jobwork:[],
            loader: false,
            tabledata:[],
            accountdata: [],

            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onSelect= (event) => {
      if (event.target.checked) {

        let jobwork= [...this.state.jobwork]
        jobwork.push(event.target.value)
        this .setState({jobwork:jobwork})
      
      console.log(jobwork)

      }else{

        let jobwork= [...this.state.jobwork]
        const index = jobwork.indexOf(event.target.value);

        jobwork.splice(index,1)
        this.setState({ jobwork:jobwork })

        console.log(jobwork)
      }
  }

    onsubmit=()=>{

      if(this.state.name===''){
        toast.error('Please Type  Name')
        
    }else{
   

      const name=this.state.name;


      
      axios.post('https://box.cmlserver.com/Backend/pages/jobworkview.php', { name })
          .then(response=>{
            this.setState({loader:false})
            console.log(response.data)
            
              
              
              this.setState({tabledata:response.data})
            
     

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }


  oncreatepi=()=>{

    if(this.state.name===''){
      toast.error('Please Type  Name')
      
  }else{

    let id= [...this.state.jobwork]

    const name=this.state.name;
    
    axios.post('https://box.cmlserver.com/Backend/pages/picreate.php', { id,name })
        .then(response=>{
          this.setState({loader:false})
          console.log(response.data)
          
            
            this.setState({jobwork: [] });
            this.setState({name: '' });
            this.setState({tabledata: [] });
            
            toast.success("Pi create Success");
   

        })
        .catch(error=>{
            toast.error(error)
            this.setState({loader:false})
        })

  
      }
      
}
componentDidMount=()=>{
  axios.post('https://box.cmlserver.com/Backend/pages/accountdrop.php',{})
  .then(response=>{

      this.setState({accountdata:response.data})
  
      
  })
  .catch(error=>{
    toast.error(error)
  })
}


    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}><u>New PI Create</u></h1>
                <br></br>

              <div className="form_div">



              <div className="form_item">
                <div style={{width: '30%'}}><label>Customer Name:</label></div>
                <div style={{width: '50%'}}><input type="text" list="data1" name="name" onChange={this.onChange} value={this.state.name}/></div>
                  <datalist id="data1">
                    {this.state.accountdata.filter((anyname) => anyname.catagory==='Customer').map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>

                
              </div>

              <div className="form_button">
                 
                     <button onClick={this.onsubmit} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Search"}
                         
                     </button>
                 
               
                
              </div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                  <th>Select</th>
                    <th>ID</th>
                    <th>Date</th>
                    <th>JOB No</th>
                    <th>PO No</th>
                    <th>USD</th>
                    <th>BDT</th>
                    <th>Narration</th>

                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                  <td>

                                                  <input type="checkbox" onChange={this.onSelect}  name="vehicle1" value={item.id}/>


                                                  </td>
                                                    <td >{item.id}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.job_work_no}</td>
                                                    <td >{item.po}</td>
                                                    <td >{item.usd}</td> 
                                                    <td >{item.amount}</td>                                                  
                                                   <td >{item.narration}</td>
                                                   
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>


                <div className="form_button">
                 
                 <button onClick={this.oncreatepi} disabled={this.state.loader}>
                     {this.state.loader===true?
                         "Loading"
                         :
                         "Create PI"}
                     
                 </button>
             
           
            
          </div>
              </div>
            </main>
          
            </>
        );
    }
}

export default Pi;