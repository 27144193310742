import React, { Component } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Dashboard extends Component {
  constructor(){
    super()

    this.state={
    
        userdata:[],
        jobdata:[]
       
    }
  }
  componentDidMount(){
      
    axios.post('https://box.cmlserver.com/Backend/reports/dashboardrpt1.php',{})
        .then(response=>{

            this.setState({userdata:response.data})
        
            
        })
        .catch(error=>{
          toast.error(error)
        })

        axios.post('https://box.cmlserver.com/Backend/reports/dashboardrpt2.php',{})
        .then(response=>{

            this.setState({jobdata:response.data})
        
            
        })
        .catch(error=>{
          toast.error(error)
        })   

}

    render() {
        return (
          <>
<Navbar></Navbar>
            <main>
  

            <div className="main__container">
           
    
              <div className="main__title">
              
                <div className="main__greeting">
                  <h1>Dashboard</h1>
                  <p>Welcome to your admin dashboard</p>
                </div>
              </div>
    

              <div className="main__cards">
              {this.state.userdata.map((item, key) =>
                <div className="card">
                  <div className="cardtitle" style={{display:"flex"}}>
                  <i className="fa fa-user-o fa-2x text-lightblue"
                    aria-hidden="true"
                  ></i> 
                  <span style={{padding:"10px"}}>{item.name}</span>
                  </div>
                  <div className="card_inner">
                    <p className="text-primary-p">Balance</p>
                    <span className="font-bold text-title" style={{color: '#3ea175'}}>{item.balance}</span>
                    
                  </div>
          
                </div>

              )}
              </div>

<hr></hr>

<div className="main__cards">
              {this.state.jobdata.map((item, key) =>
                <div className="card">
                  <div className="cardtitle" style={{display:"flex"}}>
                  <i className="fa fa-user-o fa-2x text-lightblue"
                    aria-hidden="true"
                  ></i> 
                  <span style={{padding:"10px"}}>{item.total}/{item.ok}</span>
                  </div>
                  <div className="card_inner">
                    <p className="text-primary-p">{item.name}</p>
                    <span className="font-bold text-title" style={{color: '#3ea175'}}>{item.balance}</span>
                    
                  </div>
          
                </div>

              )}
              </div>
       
             
            </div>
          </main>
         
          </>
        
        );
    }
}

export default Dashboard;