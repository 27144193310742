import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Dailyreport extends Component {

    constructor(){
        super()

        this.state={
            

            loader2: false,
            fromdate: '',
         
            todate: '',
      
        
            accountdata: [],
            companydata: [],
        
           statementdata:[],

           accdata:[],
           accnodata:[],
           jobdata:[],
           jobdetailsdata:[],
           inoutdata:[],


           sumdr:0,
           sumcr:0,
        openingbal:0,
           closingbal:0
          
           
        }
        this.onChange = this.onChange.bind(this);
        this.onReportshow = this.onReportshow.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    componentDidMount(){
      


        axios.post('https://box.cmlserver.com/Backend/reports/company.php',{})
        .then(response=>{
  
            this.setState({companydata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })



}

    onReportshow=()=>{
        this.setState({balanceshow:0})
        this.setState({accountshow:1})

      if(this.state.fromdate===''){
      this.setState({fromdatereq:1})

    }if(this.state.todate===''){
        this.setState({todatereq:1})
  
      }else{
        this.setState({fromdatereq:0}) 
        this.setState({todatereq:0}) 

     
      const fromdate=this.state.fromdate;
      const todate=this.state.todate;
    
     
  
      
     
      if(this.state.fromdate===''){
        toast.error ("Please select Date");
        
      }else{
        this.setState({loader2:true})

      axios.post('https://box.cmlserver.com/Backend/reports/dailycash.php', { fromdate,todate})
          .then(response=>{
            console.log(response.data)
            this.setState({statementdata:response.data})
            this.setState({sumdr:response.data[0]["sumdr"]})
            this.setState({sumcr:response.data[0]["sumcr"]})
            this.setState({openingbal:response.data[0]["openingbal"]})
            this.setState({closingbal:response.data[0]["closingbal"]})
            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })


          axios.post('https://box.cmlserver.com/Backend/reports/dailyaccno.php', { fromdate,todate})
          .then(response=>{
            console.log(response.data)
            this.setState({accnodata:response.data})

            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })

          axios.post('https://box.cmlserver.com/Backend/reports/dailyacc.php', { fromdate,todate})
          .then(response=>{
            console.log(response.data)
            this.setState({accdata:response.data})

            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })


          axios.post('https://box.cmlserver.com/Backend/reports/dailyjob.php', {fromdate,todate})
          .then(response=>{
            this.setState({loader:false})
            console.log(response.data)
            
              
              
              this.setState({jobdata:response.data})
            
     

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

          axios.post('https://box.cmlserver.com/Backend/reports/dailyjob2.php', { fromdate,todate})
          .then(response=>{
            this.setState({jobdetailsdata:response.data})
  
            
            
            this.setState({loader2:false})

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader2:false})
          })

          axios.post('https://box.cmlserver.com/Backend/reports/dailyinout.php',{ fromdate,todate})
          .then(response=>{
  
              this.setState({inoutdata:response.data})
          
              
          })
          .catch(error=>{
              toast.error(error)
          })


    }

        }
  }

  onPrint=()=>{
window.print();
}



    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
              <ToastContainer />

        <h1 classname="printpagehead" style={{color: '#3ea175'}}>Account Statement</h1>

        <div style={{marginLeft: '30%', marginRight: '30%'}}>


      
                <div className="form_item">
                <div style={{width: '30%'}}> <label>From Date :</label></div>
                <div><input type="date" name="fromdate" onChange={this.onChange} value={this.state.fromdate}/></div>
                  
                </div>
                

<div className="form_item"></div>

                <div className="form_item">
                <div style={{width: '30%'}}><label>To Date :</label></div>
                <div ><input type="date" name="todate" onChange={this.onChange} value={this.state.todate}/></div>
                 
                </div>

                <div className="form_item"></div>


                
                <div className="form_item"></div>
                
                <div className="form_item">
                  
                </div>
                
                <div className="form_item"></div>

                <div className="form_button">
                <button onClick={this.onReportshow} disabled={this.state.loader2}>
                          {this.state.loader2===true?
                             "Loading"
                             :
                             "Submit"}
                </button>
              </div>
                
              </div>

              <hr></hr>

              <div className="print_div" >
                
                <i className="fa fa-print" onClick={this.onPrint} style={{color:'green'}}></i>
                <table width="100%" style={{border:'0'}}>
                  <thead>
                  {this.state.companydata.map((item, key) =>
               <>
                <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><h3>{item.name}</h3></td>
                    
                </tr>
                 
                 <tr>
                 <td colspan="14" style={{textAlign:"center"}} ><h2>{item.address}</h2></td>
                 
                  </tr>
                  <tr>
                               <td colspan="14" style={{textAlign:"center"}} >{item.mobile_no}</td>
                             
                </tr>
                </>
                )}
                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} ><u>Account Statement</u></td>
                  </tr>
                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} >Account Name:Cash </td>
                  </tr>
                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} >From {this.state.fromdate} to date {this.state.todate}</td>
                  </tr>
                  <tr style={{border:'1px solid black'}}>
                    <th colspan="4">Debit</th>
                    <th colspan="4">Credit</th>
 
                    
                    
                  </tr>
                  </thead>
                  <tr style={{border:'1px solid black'}}>
                    <th colspan="3">Opening Balance</th>
                    <th colspan="3">{this.state.openingbal}</th>
 
                    
                    
                  </tr>
                  <tr style={{border:'1px solid black'}}><td colspan="4">

                                                                
                                            <tr>
                                                    
                                                    <td >Date</td>
                                                    
                                                    <td >Narration</td>
                                                    <td >Amount</td>


                                                    
                                                    
                                                </tr> 

                                            {
                                             

                                            this.state.statementdata.filter((any)=>any.rec>0).map((item, key) =>
                                            
                                              <tr>
                                                    
                                                    <td >{item.cdate}</td>
                                                    
                                                    <td >{item.narration}</td>
                                                    <td >{item.rec}</td>


                                                    
                                                    
                                                </tr>      
                                               
                                               
                                            )}
</td>
<td colSpan={4}>
<tr>
                                                    
                                                    <td >Date</td>
                                                    
                                                    <td >Narration</td>
                                                    <td >Amount</td>


                                                    
                                                    
                                                </tr> 

                                        {
                                        this.state.statementdata.filter((anymore)=>anymore.pay>0).map((item, key) =>
                                            
                                            <tr>
                                                  
                                                  <td >{item.cdate}</td>
                                                  
                                                  <td >{item.narration}</td>
                                                  <td >{item.pay}</td>


                                                
                                                  
                                              </tr>      
                                             
                                             
                                          )}

</td>
                                    
</tr>

                                            <tr style={{border:'1px solid black'}}>
                                                    <td colspan="3">Total</td>
                                                     <td >{this.state.sumdr}</td>
                                                     <td colspan="3">Total</td>
                                                    <td >{this.state.sumcr}</td>
                                                    
                                                    
                                                </tr>
                                                <tr style={{border:'1px solid black', fontStyle:'bold'}}>
                                                    <td colspan="7">Closing Balance</td>
                                                     <td >{this.state.closingbal}</td>
                                                
                                                    
                                                    
                                                </tr>
                </table>


                <table width="100%" style={{border:'0'}}>

                  {this.state.accnodata.map((itemx, key) =>
      
                    <>
                    <tr>
                    <td colspan="8" style={{textAlign:"center"}} >Account No:{itemx.accno} </td>
                  </tr>

                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} >Account Name:{itemx.name} </td>
                  </tr>
                  <tr>
                    <td colspan="8" style={{textAlign:"center"}} >From {this.state.fromdate} to date {this.state.todate}</td>
                  </tr>
                  <tr style={{border:'1px solid black'}}>
                    <th>Sl</th>
                    <th>Date</th>
                    <th>TR No</th>
                     
                    <th>Rec</th>
                    <th>Pay</th>
                    <th>C.Balance</th>
                    <th>Narration</th>
                    
                    
                  </tr>
                 

                                            {
                                             

                                            this.state.accdata.map((item, key) =>

                                               item.filter(any=>any.acc_no==itemx.accno).map((itema, key) =>
                                            
                                                <tr style={{border:'1px solid black'}}>
                                                    <td >{key+1}</td>
                                                    <td >{itema.cdate}</td>
                                                    <td >{itema.tr_no}</td>
                                                    <td >{itema.rec}</td>
                                                    <td >{itema.pay}</td>
                                                    <td >{Math.trunc(itema.csum)}</td>
                                                    <td >{itema.narration}</td>
                                                    
                                                    
                                                </tr>
                                               )
                                               
                                            )}
                                       

                
                                
                           </>
                  )}
                               </table>


                 
                <h2>Job Work</h2>
                <table>
                  <tr>
                 
                    <th>ID</th>
                    <th>Date</th>
                    <th>JOB No</th>
                    <th>PO No</th>
                    <th>USD</th>
                    <th>BDT</th>

                    <th>Narration</th>
                    <th>Details</th>

                   
                  </tr>

                                            {this.state.jobdata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                  
                                                    <td >{item.id}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.job_work_no}</td>
                                                    <td >{item.po}</td>
                                                    <td >{item.usd}</td> 
                                                    <td >{item.amount}</td>                                                  
                                                   <td >{item.narration}</td>

                                                   <td>

                                                   {
                                
                                                            this.state.jobdetailsdata.filter((anyname) => anyname.order_id===item.order_id).map((item2, key2) =>  

                                                            
                                                                <tr style={{border:'1px solid green'}}>
                                                                    
                                                                    <td>{item2.name}</td>
                                                                    <td>{Math.trunc(item2.qty)}</td>
                                                                    <td>{Math.trunc(item2.delivery)}</td>

                                                                    
                                                                </tr>
                                                            

                                                                     )
                                                     }

                                                   </td>
                                                   
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>

               
                <h2>Stock In Out</h2>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    
                    <th>Type</th>
                    <th>Name</th>
                    <th>qty</th>
                    <th>qty2</th>
                    <th>Unit</th>

                    <th>Rate</th>
                    <th>Amount</th>
                  
                   
                  </tr>

                                            {this.state.inoutdata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.type}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.qty}</td>
                                                    <td >{item.qty2}</td>
                                                   
                                                    <td >{item.unit}</td>
                                                    <td >{item.rate}</td>
                                                    <td >{item.amount}</td>
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>

              </div>
    

             
            </main>
          
            </>
        );
    }
}

export default Dailyreport;