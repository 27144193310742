
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Lastjob extends Component {

    constructor(){
        super()

        this.state={
            
           
            loader: false,
           stockdata:[],
           stockdetailsdata:[],
           order_no:"",



          
           
        }


    }




      componentDidMount(){
      
        
        axios.post('https://box.cmlserver.com/Backend/reports/piview.php',{})
        .then(response=>{
  
            this.setState({stockdata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })

        axios.post('https://box.cmlserver.com/Backend/reports/piview2.php',{})
        .then(response=>{
  
            this.setState({stockdetailsdata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })
    
    }

    onPrint=()=>{
      window.print();
    }
    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
              <ToastContainer />

        <h1 className="printpagehead" style={{color: '#3ea175'}}>Stock Balance</h1>



         
             

              <div className="print_div">
              <i className="fa fa-print" onClick={this.onPrint} style={{color:'green'}}></i>
              <table style={{border:'0'}}>
                <thead>
                <tr>
                    <td colspan="14" style={{textAlign:"center",backgroundColor:"white",border:"0px"}} ><img src="https://box.cmlserver.com/Backend/logo.jpg" height="80px" alt="Logo"/></td>
                  </tr>
                  <tr>
                    <td colspan="14" style={{textAlign:"center",backgroundColor:"white",border:"0px"}} ><h2>Job Work </h2></td>
                  </tr>

                  
                  <tr style={{border:'1px solid black'}}>
                    <th>SL</th>
                    <th>Date</th>
                    <th>Pi No</th>
                    <th>Name</th>
                    <th>job Work No</th>
                    <th>Job Status</th>
                    <th>PI Status</th>
                    <th>Narration</th>
                  

                   
                  </tr>
                  </thead>

                             {
                                
                             this.state.stockdata.map((item, key) =>
                
                                 
                           
                            
                            


                     
                                                <tr style={{border:'1px solid black'}}>
                                                    <td >{key+1}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.pi_no}</td>
                                                    <td >{item.name}</td>
                                                    <td >Job Work No: {item.job_work_no}

                                                    <tr style={{border:'1px solid green'}}>
                                                                    
                                                                    <td>Item name</td>
                                                                    <td>Qty</td>
                                                                   <td>Drelivery</td>
                                                                   <td>Differ</td>

                                                                </tr>
                                                    
                                                    {
                                
                                                         this.state.stockdetailsdata.filter((anyname) => anyname.order_id===item.order_id).map((item2, key2) =>  

                                                          
                                                                <tr style={{border:'1px solid green'}}>
                                                                    
                                                                    <td>{item2.name}</td>
                                                                    <td>{item2.qty}</td>
                                                                   <td>{item2.delivery}</td>
                                                                   <td style={{border:'1px solid green'}}>{item2.qty-item2.delivery}</td>
                                                                
                                                                </tr>
                                                            

                                                    )
                                                    }
                                                    
                                                    
                                                    
                                                    
                                                    </td>
                                                    <td >{item.status}</td>
                                                    <td >{item.pi_no_status}</td>
                                                    <td >{item.narration}</td>
                                                   
                                                  
                                                    
                                                </tr>
                                            )}
                  
                </table>

              </div>


    

             
            </main>
          
            </>
        );
    }
}

export default Lastjob;