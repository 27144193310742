import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class JobWork extends Component {

    constructor(){
        super()

        this.state={
            cusname: '',
            amount: '',
            narration:'',
            name: '',
            qty: '',
            po: '',
            usd: '',

            
            loader: false,
            stockdata:[],
            stockdatadrop:[],
            qtydata:[],
            accountdata: [],
            

            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{

      if(this.state.cusname===''){
        toast.error('Please Type  Customer Name')
        
    }else if(this.state.amount===''){
      toast.error('Please Select BDT')

    }else if(this.state.usd===''){
      toast.error('Please Select USD')

    }else if(this.state.narration===''){
      toast.error('Please type narration')

    
    }else{
   

      const cusname=this.state.cusname;
      const amount=this.state.amount;
      const narration=this.state.narration;
      const po=this.state.po;
      const usd=this.state.usd;
      let qtydata= [...this.state.qtydata]
      let stockdata= [...this.state.stockdata]

      
      axios.post('https://box.cmlserver.com/Backend/pages/jobwork.php', { cusname,amount,narration,stockdata,qtydata,po,usd })
          .then(response=>{
            this.setState({loader:false})
            console.log(response)
            if(response.data===1){
              
              this.setState({cusname: '' });
              this.setState({amount: '' });
              this.setState({narration: '' });
              this.setState({po: '' });
              this.setState({usd: '' });
              this.setState({stockdata: [] });
              this.setState({qtydata: [] });
  
              toast.success("Save Success");
              this.componentDidMount();
              

            

            }
              else{
              toast.warning(response.data);
          }

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }

  productremove=(key)=>(event)=>{
                   
 
     
    let stockdata= [...this.state.stockdata]
    let qtydata= [...this.state.qtydata]


    stockdata.splice(key,1)
    this.setState({ stockdata:stockdata }) 

    qtydata.splice(key,1)
    this.setState({ qtydata:qtydata }) 

}

  onsubmitstock=()=>{
    let stockdata= [...this.state.stockdata]

             // this.setState({stock:response.data.name})
             
             stockdata.push(this.state.name)
             this .setState({stockdata:stockdata})
             let qtydata= [...this.state.qtydata]
             qtydata.push(this.state.qty)  
             this .setState({qtydata:qtydata})
             this .setState({name:''})
             this .setState({qty:''})




            

  }


componentDidMount=()=>{

  axios.post('https://box.cmlserver.com/Backend/pages/stockdrop.php',{})
  .then(response=>{

      this.setState({stockdatadrop:response.data})
  
      
  })
  .catch(error=>{
    toast.error(error)
  })

  axios.post('https://box.cmlserver.com/Backend/pages/accountdrop.php',{})
  .then(response=>{

      this.setState({accountdata:response.data})
  
      
  })
  .catch(error=>{
    toast.error(error)
  })
}

    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}><u>New Job Work</u></h1>
                <br></br>

                <div className="form_divx">

<div className="form_item">
  <div style={{width: '30%'}}><label>Account:</label></div>
  <div style={{width: '50%'}} ><input type="text" list="data2" name="cusname" onChange={this.onChange} value={this.state.cusname}/></div>
    <datalist id="data2">
      {this.state.accountdata.filter((anyname) => anyname.catagory==='Customer').map((item, key) =>
      <option>{item.name}</option>
      )}
    </datalist>
  </div>

  <div className="form_item" >
<div style={{width: '30%'}}><label>PO :</label></div>
<div style={{width: '50%'}}><input type="text" name="po" onChange={this.onChange} value={this.state.po}/></div>

</div> 
<div className="form_item" >
<div style={{width: '30%'}}><label>USD :</label></div>
<div style={{width: '50%'}}><input type="text" name="usd" onChange={this.onChange} value={this.state.usd}/></div>

</div> 

<div className="form_item" >
<div style={{width: '30%'}}><label>BDT :</label></div>
<div style={{width: '50%'}}><input type="text" name="amount" onChange={this.onChange} value={this.state.amount}/></div>

</div>
<div className="form_item" >
<div style={{width: '30%'}}><label>Narration :</label></div>
<div style={{width: '50%'}}><input type="text" name="narration" onChange={this.onChange} value={this.state.narration}/></div>

</div>  

<div className="form_button">
  
  <button onClick={this.onsubmit} disabled={this.state.loader}>
      {this.state.loader===true?
          "Loading"
          :
          "Submit"}
      
  </button>
 </div>
</div>
<br></br>
<br></br>

         <div className="form_divx">



         <div className="form_item">
                <div style={{width: '30%'}}><label>Item Name:</label></div>
                <div  style={{width: '50%'}}><input type="text" list="data1" name="name" onChange={this.onChange} value={this.state.name}/></div>
                  <datalist id="data1">
                    {this.state.stockdatadrop.filter((anyname) => anyname.type==='Ready Good').map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>
              
              

              <div className="form_item" >
              <div style={{width: '30%'}}><label>QTY. :</label></div>
              <div style={{width: '50%'}}><input type="text" name="qty" onChange={this.onChange} value={this.state.qty}/></div>
        
              </div>


              <div className="form_button">
                
                <button onClick={this.onsubmitstock} disabled={this.state.loader}>
                    {this.state.loader===true?
                        "Loading"
                        :
                        "Submit"}
                    
                </button>
               </div>


 
                
              </div>



              <div className="table_div">
                <h2>Invoice</h2>
                <table>
                  <tr>
                    
                  <th>ID</th>
                    <th>Name</th>
                    <th>qty</th>
                    <th>Edit</th>

                  
                   
                  </tr>

                                            {this.state.stockdata.map((item, key) =>
                     
                     
                                                <tr style={{border:'1px solid black'}}>
                                                    
                                                    <td >{key+1}</td>
                                                    <td >{item}</td>
                                                    <td >{this.state.qtydata[key]}</td>
                                                    <td onClick={this.productremove(key)} ><i className="fa fa-trash-o"></i></td>
                                                
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>
              </div>
<hr></hr>
              


            </main>
          
            </>
        );
    }
}

export default JobWork;