import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class StockInOut extends Component {

    constructor(){
        super()

        this.state={
            name: '',
            qty: '',
            qty2: '',
            type:'',
           
            rate:'',
            narration:'',
            
            loader: false,
            tabledata:[],
            stockdata:[],
            
            updatebtn:0,
            editid:'',
            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onsubmit=()=>{

      if(this.state.name===''){
        toast.error('Please Type  Name')
        
    }else if(this.state.qty===''){
      toast.error('Please type qty')

    }else if(this.state.qty2===''){
      toast.error('Please type qty')

    }
    else if(this.state.rate===''){
      toast.error('Please type rate')

    }
    else if(this.state.type===''){
        toast.error('Please Select Type')
  
    }else if(this.state.narration===''){
        toast.error('Please Select Narration')
  
    }else{
   

      const name=this.state.name;
      const qty=this.state.qty;
      const qty2=this.state.qty2;
      const rate=this.state.rate;
      const type=this.state.type;
      
      const narration=this.state.narration;

      
      axios.post('https://box.cmlserver.com/Backend/pages/stockinout.php', { name,qty,qty2,rate,type,narration })
          .then(response=>{
            this.setState({loader:false})
            console.log(response)
            if(response.data===1){
              
              this.setState({name: '' });
              this.setState({qty: '' });
              this.setState({qty2: '' });
              this.setState({rate: '' });
              this.setState({type: '' });
             
              this.setState({narration: '' });
              toast.success("Save Success");
              this.componentDidMount();
              

            

            }
              else{
              toast.warning(response.data);
          }

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }





  componentDidMount(){
      
      axios.post('https://box.cmlserver.com/Backend/pages/stockinoutview.php',{})
          .then(response=>{
  
              this.setState({tabledata:response.data})
          
              
          })
          .catch(error=>{
              toast.error(error)
          })

          axios.post('https://box.cmlserver.com/Backend/pages/stockdrop.php',{})
          .then(response=>{
  
              this.setState({stockdata:response.data})
          
              
          })
          .catch(error=>{
            toast.error(error)
          })
   

  }

    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: '#3ea175'}}><u>Stock In </u></h1>
                <br></br>

              <div className="form_divx">

              <div className="form_item">
                <div style={{width: '30%'}}><label>Type:</label></div>
                <div style={{width: '50%'}}> <input type="text" list="data5" name="type" onChange={this.onChange} value={this.state.type}/></div>
                    <datalist id="data5">
                            <option >In</option>
                            
                            
                      </datalist>
                </div>

                <div className="form_item">
                <div style={{width: '30%'}}><label>Item Name:</label></div>
                <div  style={{width: '50%'}}><input type="text" list="data1" name="name" onChange={this.onChange} value={this.state.name}/></div>
                  <datalist id="data1">
                    {this.state.stockdata.filter((any)=>any.type=='Raw Material').map((item, key) =>
                    <option>{item.name}</option>
                    )}
                  </datalist>
                </div>
              
              

              <div className="form_item" >
              <div style={{width: '30%'}}><label>QTY. :</label></div>
              <div style={{width: '50%'}}><input type="text" name="qty" onChange={this.onChange} value={this.state.qty}/></div>
        
              </div>
              <div className="form_item" >
              <div style={{width: '30%'}}><label>Roll :</label></div>
              <div style={{width: '50%'}}><input type="text" name="qty2" onChange={this.onChange} value={this.state.qty2}/></div>
        
              </div>


              <div className="form_item" >
              <div style={{width: '30%'}}><label>Rate :</label></div>
              <div style={{width: '50%'}}><input type="text" name="rate" onChange={this.onChange} value={this.state.rate}/></div>
        
              </div>   
              <div className="form_item" >
              <div style={{width: '30%'}}><label>Narration :</label></div>
              <div style={{width: '50%'}}><input type="text" name="narration" onChange={this.onChange} value={this.state.narration}/></div>
        
              </div>

 
                
              </div>

              <div className="form_button">
                
                     <button onClick={this.onsubmit} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Submit"}
                         
                     </button>
                 
               
                
              </div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    
                    <th>Type</th>
                    <th>Name</th>
                    <th>qty</th>
                    <th>qty2</th>
                    <th>Unit</th>

                    <th>Rate</th>
                    <th>Amount</th>
                  
                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr style={{border: '1px solid black'}}>
                                                    <td >{item.id}</td>
                                                    <td >{item.cdate}</td>
                                                    <td >{item.type}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.qty}</td>
                                                    <td >{item.qty2}</td>
                                                   
                                                    <td >{item.unit}</td>
                                                    <td >{item.rate}</td>
                                                    <td >{item.amount}</td>
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>
              </div>
            </main>
          
            </>
        );
    }
}

export default StockInOut;