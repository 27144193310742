import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Jobdelivery extends Component {

    constructor(){
        super()

        this.state={
            jobid: '',

            
            loader: false,
            tabledata:[],
            deliverydata:[],

            
        }
        this.onChange = this.onChange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onadd =(id,key)=>(event)=> {

        let deliverydata= [...this.state.deliverydata]
        deliverydata[key]=[id,event.target.value]
        
       // deliverydata.push([id,event.target.value])
        this .setState({deliverydata:deliverydata})
        console.log(deliverydata)

    }

    onsubmit=()=>{

      if(this.state.jobid===''){
        toast.error('Please Type  job id')
        
    }else{
   

      const jobid=this.state.jobid;


      
      axios.post('https://box.cmlserver.com/Backend/pages/selectjobview.php', { jobid })
          .then(response=>{
            this.setState({loader:false})
            console.log(response.data)
            
              
              
              this.setState({tabledata:response.data})


              toast.success("Save Success");
     

          })
          .catch(error=>{
              toast.error(error)
              this.setState({loader:false})
          })

    

        }
  }


  oncreatepi=()=>{

    if(this.state.jobid===''){
      toast.error('Please Type  job id')
      
  }else{

    let deliverydata= [...this.state.deliverydata]

    const jobid=this.state.jobid;
    
    axios.post('https://box.cmlserver.com/Backend/pages/jobdelivery.php', { jobid,deliverydata })
        .then(response=>{
          this.setState({loader:false})
          console.log(response.data)
          
            
            this.setState({deliverydata: [] });
            this.setState({jobid: '' });
            this.setState({tabledata: [] });
            
            toast.success("Job delivery success Success");
   

        })
        .catch(error=>{
            toast.error(error)
            this.setState({loader:false})
        })

  
      }
      
}



    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
              </Helmet>
              <ToastContainer />

                <h1 style={{color: 'black'}}><u>Job Delivery</u></h1>
                <br></br>

              <div className="form_div">



              <div className="form_item" >
              <div style={{width: '30%'}}><label>Job ID :</label></div>
              <div style={{width: '50%'}}><input type="text" name="jobid" onChange={this.onChange}  value={this.state.jobid}/></div>
        
              </div>

                
              </div>

              <div className="form_button">
                 
                     <button onClick={this.onsubmit} disabled={this.state.loader}>
                         {this.state.loader===true?
                             "Loading"
                             :
                             "Search"}
                         
                     </button>
                 
               
                
              </div>

              <div className="table_div">
                <h2>Information</h2>
                <table>
                  <tr>
                  
                    <th>ID</th>
                    <th>Item Name</th>
                    <th>Qty</th>
                    <th>Delivery</th>
                    <th>Differ</th>
                    <th>New Delivery</th>

                   
                  </tr>

                                            {this.state.tabledata.map((item, key) =>
                     
                     
                                                <tr>

                                                    <td >{item.id}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.qty}</td>                                                 
                                                   <td >{item.delivery}</td>
                                                   <td >{item.qty-item.delivery}</td>
                                                   <td >

                                                   <input type="text" name="delivery" onBlur={this.onadd(item.id,key)}  />

                                                   </td>
                                                   
                                                   
                                                    
                                                </tr>
                                            )}
                  
                </table>


                <div className="form_button">
                 
                 <button onClick={this.oncreatepi} disabled={this.state.loader}>
                     {this.state.loader===true?
                         "Loading"
                         :
                         "Delivered"}
                     
                 </button>
             
           
            
          </div>
              </div>
            </main>
          
            </>
        );
    }
}

export default Jobdelivery;