import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';

class Stockdetails extends Component {

    constructor(){
        super()

        this.state={
            
           
            loader: false,
           stockdata:[],
           companydata:[],



          
           
        }


    }




      componentDidMount(){
      
        
        axios.post('https://box.cmlserver.com/Backend/reports/producthistory3.php',{})
        .then(response=>{
  
            this.setState({stockdata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })
        axios.post('https://box.cmlserver.com/Backend/reports/company.php',{})
        .then(response=>{
  
            this.setState({companydata:response.data})
 

            
        })
        .catch(error=>{
          toast.error(error)
          this.setState({loader:false})
        })

    
    }

    onPrint=()=>{
      window.print();
    }
    render() {
        return (
          <>
          <Navbar></Navbar>
            <main>
              <Helmet>
                <link rel="stylesheet" href="form.css" />
                <link rel="stylesheet"  href="print.css" />
              </Helmet>
              <ToastContainer />

        <h1 className="printpagehead" style={{color: '#3ea175'}}>Item Balance</h1>



         
             

              <div className="print_div">
              <i className="fa fa-print" onClick={this.onPrint} style={{color:'green'}}></i>
              <table>
                <thead>
                {this.state.companydata.map((item, key) =>
               <>
                <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><h3>{item.name}</h3></td>
                    
                </tr>
                 
                 <tr>
                 <td colspan="14" style={{textAlign:"center"}} ><h2>{item.address}</h2></td>
                 
                  </tr>
                  <tr>
                               <td colspan="14" style={{textAlign:"center"}} >{item.mobile_no}</td>
                             
                </tr>
                </>
                )}
              
                  <tr>
                    <td colspan="14" style={{textAlign:"center"}} ><u>Item Report</u></td>
                  </tr>

                  
                  <tr>
                    <th>SL</th>

                    <th>Name</th>
                    <th>unit</th>
                    <th>Qty (weight) </th>
                    
                    <th>Qty (Roll) </th>
                    
                    

                   
                  </tr>
                  </thead>

                                            {this.state.stockdata.map((item, key) =>
                     
                     <>
                                                <tr style={{border:'1px solid black',color:'green'}}>
                                                    <td >{key+1}</td>
                                                    <td >{item.name}</td>
                                                    <td >{item.unit}</td>
                                                    <td >{item.qty}</td>
                                                    <td >{item.qty2}</td>                                              
                                                </tr>
                                                {item.details.map((itemx, keyx) =>
                                                    <tr style={{border:'1px solid black'}}>
                                                    <td >{key+1}</td>
                                                    <td >{itemx.qty}</td>
                                                    <td >Out : {itemx.out_qty}</td>
                                                    <td >{itemx.balance}</td>
                                                    <td >Roll :{itemx.qty2}</td>                                              
                                                </tr>
                                                )}
                        </>
                                            )}
                  
                </table>

              </div>


    

             
            </main>
          
            </>
        );
    }
}

export default Stockdetails;